document.addEventListener('DOMContentLoaded', () => {
  'use strict'

  /*==================================
   ANIMATION PROGRAMS
  ==================================*/
  var tabs = document.querySelectorAll(".img-programs img");
  setInterval(function () {
    var i = Math.floor(Math.random() * tabs.length);
    var img = tabs[i];
    img.classList.add("animate");
    img.addEventListener("animationend", function () {
      tabs.forEach(function (image) {
        image.classList.remove("animate");
      });
    }, { once: true });
  }, 3000);

  /*==================================
   PLAY VIDEO
  ==================================*/
  var video = document.getElementById("videotts");
  video.play();

  // setTimeout(function () {
  //   document.getElementById('videotts').play();
  // }, 3);

  /*==================================
   COUNTER UP
  ==================================*/

  function countItUp(numb) {
    var secondsLabel = document.querySelector(".count");
    var totalSeconds = 0;

    var countdown = setInterval(function () {
      totalSeconds++;
      secondsLabel.innerHTML = totalSeconds;

      if (totalSeconds >= numb) {
        clearInterval(countdown);
      }
    }, 100);
  }
  countItUp(200);

  function countItUp2(numb) {
    var secondsLabel = document.querySelector(".count2");
    var totalSeconds = 0;

    var countdown = setInterval(function () {
      totalSeconds++;
      secondsLabel.innerHTML = totalSeconds;

      if (totalSeconds >= numb) {
        clearInterval(countdown);
      }
    }, 100);
  }
  countItUp2(150);

  function countItUp3(numb) {
    var secondsLabel = document.querySelector(".count3");
    var totalSeconds = 0;

    var countdown = setInterval(function () {
      totalSeconds++;
      secondsLabel.innerHTML = totalSeconds;

      if (totalSeconds >= numb) {
        clearInterval(countdown);
      }
    }, 100);
  }
  countItUp3(100);


  // TEXT ANIMATION TYPED.JS
  var typed = new Typed('.texTyped', {
    strings: [
      "مجموعة من الحلول البرمجية",
      "أنظــمة",
      "منصــات",
      "تطبيقـــات",
    ],
    typeSpeed: 60,
    loop: true,
    backSpeed: 10,
    startDelay: 0,
    fadeOut: true,
    cursorChar: ""
  });

});


window.onload = function () {
  const SW9 = new SiriWave({
    style: "ios9",
    container: document.getElementById("animation-siri"),
    autostart: true,
    amplitude: 0.6,
  });
};